import ScrollMonitor from 'scrollmonitor';

class NavigationController {
    constructor() {
        this.elementWatchers = [];
    }

    init() {
        let button = $('button.nav-overlay-toggle');
        let navOverlay = $('.nav-overlay');

        let navOverlayItems = navOverlay.find('.links a');
        let navOverlayBackgroundItems = navOverlay.find('.menu-background');

        let navbar = $('nav.main-nav');
        let darkElements = $('.section.is-dark:not(.nav-overlay), .section.is-grey:not(.nav-overlay)');

        button.on('click', function () {
            button.toggleClass('is-active');
            navOverlay.toggleClass('is-active');
        });

        navOverlayItems.on('mouseover', function () {
            navOverlayBackgroundItems.removeClass('is-active');
            navOverlayBackgroundItems.filter('.' + $(this).data('target')).addClass('is-active');
        });

        navOverlayItems.on('mouseout', function () {
            navOverlayBackgroundItems.filter('.' + $(this).data('target')).removeClass('is-active');
        });

        /* -- Color Change in Dark Sections -- */
        let isInitiallyLight = false;

        darkElements.each((index, element) => {
            let elementWatcher = ScrollMonitor.create($(element).get(0));
            this.elementWatchers.push(elementWatcher);

            elementWatcher.stateChange((event, watcher) => {
                if (watcher.isAboveViewport || $(document).scrollTop() === watcher.top) {
                    navbar.toggleClass('is-light', watcher.isInViewport && watcher.isAboveViewport || $(document).scrollTop() === watcher.top);
                }
            });

            // If any of the elements are overlapping, add the is-light class
            // don't remove it, since elements farther down the page will incorrectly remove it
            if (elementWatcher.isInViewport && elementWatcher.isAboveViewport || $(document).scrollTop() == elementWatcher.top) {
                isInitiallyLight = true;
            }
        });

        navbar.toggleClass('is-light', isInitiallyLight);
    }

    destroy() {
        for (let i = 0; i < this.elementWatchers.length; i++) {
            this.elementWatchers[i].destroy();
        }

        this.elementWatchers = [];
    }
}

export default NavigationController;