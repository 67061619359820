import ScrollMonitor from 'scrollmonitor';
import lity from 'lity';
import lazysizes from 'lazysizes';

import NavigationController from './NavigationController';

class MainController {
    // main class initialization - runs once on site load
    constructor() {
        // Create class variables
        this.scrollMonitor = ScrollMonitor;
        this.scrollWatchers = [];
        this.sliders = [];
        this.contactForm = null;
        this.navigationController = null;
        this.iconLibrary = null;
        this.content_block_modules = [];

        lazysizes.cfg.init = false; // disable lazysizes autoinit, to fix non-loaded image bug when moving between pages

        // hide preloader 
        $('.revealer').removeClass('show').addClass('animate-out');
        setTimeout(function () {
            $('.revealer').removeClass('animate-out');
        }, 600);
    }

    // initialization - runs each time a new page is loaded
    init() {
        try {
            //$(document).foundation();
            $('body').addClass('animations-enabled');
            var _instance = this;

            // initialize navigation controller
            this.navigationController = new NavigationController();
            this.navigationController.init();

            // initialize lazysizes on every new page, and reinitialize after a short delay to ensure page transitions are complete
            lazysizes.init();
            setTimeout(() => {
                lazysizes.init();
                $('img.lazyloading').removeClass('lazyloading').addClass('lazyloaded'); // force removal of lazyloading class after 1 second.
            }, 1000);
            

            // initiate lity lightboxes
            $(document).on('click.bws', '[data-lightbox]', lity);

            // run element animations when in viewport (adds .is-active to visible elements as you scroll)
            $('.animatable').each(function (index) {
                // create two watchers - one with an offset for enter events, and one without an offset for exit events
                // lets us add visibility classes with an offset, and remove them when the element is completely outside the visible viewport
                let enterScrollWatcher = scrollMonitor.create($(this).get(0), -100);
                let exitScrollWatcher = scrollMonitor.create($(this).get(0), 100);
                _instance.scrollWatchers.push(enterScrollWatcher);
                _instance.scrollWatchers.push(exitScrollWatcher);


                enterScrollWatcher.enterViewport(function () {
                    $(this.watchItem).addClass('is-active');
                });

                exitScrollWatcher.exitViewport(function () {
                    $(this.watchItem).removeClass('is-active');
                });

                // If any of the elements are visible, add the active class (after an initial delay, to facilitate page transition animation)
                $(this).addClass('will-animate');
                $(this).removeClass('is-active');

                if (exitScrollWatcher.isInViewport) {
                    setTimeout(() => {
                        $(exitScrollWatcher.watchItem).addClass('is-active');
                    }, 50);
                }
            });

            // initialize sliders 
            if ($('.team-slider.slider').length) {
                import(/* webpackChunkName: "flickity" */ 'flickity')
                    .then(({ default: Flickity }) => {
                        let teamSlider = new Flickity('.team-slider.slider .track', {
                            freeScroll: true,
                            groupCells: true,
                            contain: true,
                            friction: 0.3,
                            //pageDots: false,
                            //prevNextButtons: false,
                        });

                        teamSlider.on('staticClick', function (event, pointer, cellElement, cellIndex) {
                            $(cellElement).find('.image-overlay').toggleClass('visible');
                        });

                        this.sliders.push(teamSlider);
                    });
            }
            //initialize vue contact forms 
            if ($('.section.contact-section').length) {
                import(/* webpackChunkName: "contact-form-controller" */ './ContactFormController')
                    .then(({ default: ContactFormController }) => {
                        this.contactForm = new ContactFormController();
                        this.contactForm.init();
                    });
            }

            // import icons library and dependencies, initialize when done
            import(/* webpackChunkName: "icons-library" */"./IconLibrary").then(module => {
                const IconLibrary = module.default;

                this.iconLibrary = new IconLibrary();
                this.iconLibrary.init();
            });

            this.initContentBlockModules();
        } catch (e) {
            console.log(e);
        }
    }

    // Called in init() method - initializes all content block modules
    initContentBlockModules() {
        // init reviews slider
        if ($('.section-reviews_slider--block').length) {
            import(/* webpackChunkName: "reviews-slider" */"./block-modules/ReviewsSlider").then(module => {
                const ReviewsSlider = module.default;

                if ($(ReviewsSlider.selector).length) {
                    let reviewsSlider = new ReviewsSlider();
                    reviewsSlider.init();
                    this.content_block_modules.push(reviewsSlider);
                }
            });
        }
    }

    // Runs every time a new page replaces the current one
    // Destroys all scripts on the page, so they can be reinitialized on the new one
    destroy() {
        try {
            // destroy lity lightboxes
            $(document).off('click.bws', '[data-lightbox]');

            // destroy content blocks
            for (let i = 0; i < this.content_block_modules.length; i++) {
                this.content_block_modules[i].destroy();
            }
            this.content_block_modules = [];

            // destroy navigation controller
            this.navigationController.destroy();
            this.navigationController = null;

            // destroy sliders 
            for (let i = 0; i < this.sliders.length; i++) {
                this.sliders[i].destroy();
            }

            // destroy contact form 
            if (this.contactForm) {
                this.contactForm.destroy();
                this.contactForm = null;
            }

            // destroy fonts
            if (typeof this.iconLibrary !== 'undefined') {
                this.iconLibrary.destroy();
                this.iconLibrary = null;
            }

            // destroy element animation scroll watchers 
            for (let i = 0; i < this.scrollWatchers.length; i++) {
                this.scrollWatchers[i].destroy();
            }

            this.scrollWatchers = [];
        } catch (e) {
            console.log(e);
        }
    }
}

export default MainController;